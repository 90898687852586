/** @jsx jsx */
import { jsx, Flex, Box, Spinner } from 'theme-ui'
import Layout from '../components/Layout'
import Container from '../components/Container'
import Header from '../components/Header'
import theme from '../gatsby-plugin-theme-ui'
import { graphql, Link } from 'gatsby'
import SEO from '../components/SEO'
import { Fragment, useRef, useState } from 'react'
import Card from '../components/Card'
import CardList from '../components/CardList'
import CollapsibleContent from '../components/CollapsibleContent'
import PartnerBanner from '../components/PartnerBanner'
import useScrollTo from '../hooks/useScrollTo'
import NWSAlert from '../components/NWSAlert'
import useGetWeather from '../hooks/useGetWeather'
import useGenerateLocationRiskInformation from '../hooks/useGenerateLocationRiskInformation'
import Map, { Marker } from 'react-map-gl'
// import { useQueryCustom } from '../hooks/useQueryCustom'
import { useQuery, gql } from '@apollo/client'

import WaterWave from '../../static/images/wave'
import 'mapbox-gl/dist/mapbox-gl.css'

// added the following 6 lines.
import mapboxgl from 'mapbox-gl'

import WidgetContainer from '../components/shared/WidgetContainer'

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore

mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

const GET_AGENCY_CONFIGS = gql`
  query GetAgencyConfigs($dashboards: [DashboardConfigWhereInput!]) {
    getAgencyDashboardConfigs(dashboards: $dashboards) {
      id
      latitude
      longitude
      agencyTz
      noaaTidesStation
      surflineSpotId
      globalOverride
      locationOverrides
    }
  }
`

function mergeObjects(obj1 = {}, obj2 = {}) {
  const mergedObj = {}
  for (const key of Object.keys(obj1)) {
    if (obj1[key] !== undefined && obj1[key] !== null) {
      mergedObj[key] = obj1[key]
    }
  }
  for (const key of Object.keys(obj2)) {
    if (obj2[key] !== undefined && obj2[key] !== null) {
      mergedObj[key] = obj2[key]
    }
  }
  return mergedObj
}

const County = ({ data }) => {
  const [scrollToRef, setShouldScrollTo] = useScrollTo()
  const regions = data.allContentfulCounty.nodes?.[0].regions
  const countyData = {
    name: data.allContentfulCounty.nodes?.[0].name,
    slug: data.allContentfulCounty.nodes?.[0].slug,
    banner: data.allContentfulCounty.nodes?.[0].banner,
  }
  const stateData = {
    name: data.allContentfulState.edges?.[0]?.node.name,
    slug: data.allContentfulState.edges?.[0]?.node.slug,
  }
  const noaaCountyCodes = data?.allContentfulCounty?.nodes[0]?.noaaCountyCodes
  const countyDescription =
    data.allContentfulCounty?.nodes[0]?.descriptionText?.childMarkdownRemark
      ?.html ||
    `Using current weather, surf, public safety alerts, and beach conditions we calculate hazard levels at ${countyData?.name} beaches.  Select a beach from the list to learn more about current conditions and activities.`

  const countyBannerData =
    data?.allContentfulCounty?.nodes[0]?.countyLevelBanner

  // flatten all beaches in the regions
  const beaches = regions
    ?.map((r) =>
      r.beaches?.map((b) => ({
        ...b,
        latitude: b?.customWeatherLatLng?.lat,
        longitude: b?.customWeatherLatLng?.lon,
        noaaSurfState: b?.customWeatherNoaaState?.split('_')[0],
        noaaSurftStateDirection: b?.customWeatherNoaaState?.split('_')[1],
        surflineSpotId: b?.customWeatherSurflineSpotId,
        noaaTidesStation: b?.customWeatherNoaaTideStation,
        agencyTz: b?.timezone,
      }))
    )
    .flat()

  // query for configuration data about all beaches
  const { data: queryData, loading } = useQuery(GET_AGENCY_CONFIGS, {
    variables: {
      dashboards: beaches
        ?.filter((b) => b?.watchtowerAgencyId)
        ?.map((b) => ({
          agencyId: b?.watchtowerAgencyId,
          locationIds:
            b?.towers?.filter((t) => t.towerId)?.map((t) => t?.towerId) ?? [],
        })),
    },
  })

  const liveAgencyData = queryData?.getAgencyDashboardConfigs

  // merge the configuration data with the beach data from contentful.
  // Contentful data takes precendence
  const beachDataForWeatherArgs = beaches?.map((b) => {
    const liveData =
      liveAgencyData?.find((la) => la.id === b?.watchtowerAgencyId) || {}
    return mergeObjects(liveData, b)
  })

  const { weatherData, weatherDataLoading } = useGetWeather(
    beachDataForWeatherArgs,
    queryData && beachDataForWeatherArgs?.some((b) => b.agencyTz)
  )

  const beachesWithRiskData =
    weatherData &&
    regions
      .map((r, k) => {
        return (
          r?.beaches?.map((b, i) => {
            const wd =
              weatherData[
                regions
                  .slice(0, k) // get all previous regions
                  .reduce((total, prevRegion) => {
                    return total + (prevRegion?.beaches?.length || 0)
                  }, 0) + i
              ]
            const liveData = liveAgencyData?.find(
              (lad) => lad.id === b?.watchtowerAgencyId
            )

            return {
              ...b,
              lat: b?.customWeatherLatLng?.lat || liveData?.latitude,
              lon: b?.customWeatherLatLng?.lon || liveData?.longitude,
              ...useGenerateLocationRiskInformation({
                aquaticRiskLevels: b?.towerHazardRatings,
                globalOverride: liveData?.globalOverride,
                weatherData: wd,
              }),
              waveHeight: wd?.waveHeight?.formatted,
            }
          }) || []
        )
      })
      .flat()
      ?.filter((a) => a.lat && a.lon)

  return (
    <Layout>
      <SEO
        title={countyData.name}
        description={`Weather, ocean conditions and safety information for ${countyData.name}.  Information provided directly by the lifeguard and fire departments.`}
        image={data.contentfulAsset && data.contentfulAsset.file.url}
      />
      <Container>
        <Flex
          sx={{
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Header
            height={'30vh'}
            headerText={data.allContentfulCounty.nodes?.[0].name}
            image={countyData?.banner?.gatsbyImageData}
            imageAltText={
              countyData?.banner?.description || countyData?.banner?.title
            }
            imageCred={countyData?.banner?.description}
          />
          <Container mw padded>
            <Box sx={{ mt: 45, marginBottom: '27px' }}>
              <Link
                sx={{ color: theme.colors.navLink, textDecoration: 'none' }}
                to={`/state/${stateData.slug}`}
              >
                <span>{stateData.name}</span>
              </Link>
              <span sx={{ color: theme.colors.navLink }}>/</span>
              <Link
                sx={{ color: theme.colors.navLink }}
                to={`/county/${countyData.slug}`}
              >
                <span sx={{ color: theme.colors.navLink }}>
                  {countyData.name}
                </span>
              </Link>
            </Box>

            {countyDescription && (
              <CollapsibleContent
                content={countyDescription}
                markdown={true}
                linesToShow={5}
                handleScroll={() => setShouldScrollTo(true)}
                containerStyles={{
                  paddingTop: '20px',
                  marginBottom: 45,
                }}
                textStyles={{ fontSize: [3, 4] }}
                buttonStyles={{ fontSize: [3, 4] }}
              />
            )}
            <NWSAlert zoneCodes={noaaCountyCodes} />
            {beachesWithRiskData?.length < 1 &&
            !weatherDataLoading &&
            !loading ? (
              <Fragment />
            ) : (
              <WidgetContainer
                sx={{
                  display: 'flex',
                  height: '500px',
                  mt: 40,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {weatherDataLoading || loading ? (
                  <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
                    <Spinner width={25} /> Grabbing conditions...
                  </Flex>
                ) : beachesWithRiskData?.length > 0 ? (
                  <MapView markers={beachesWithRiskData} />
                ) : (
                  'Map unavailable'
                )}
              </WidgetContainer>
            )}
            {regions?.map((region, k) => {
              return (
                <Box key={k} sx={{ mt: 45 }}>
                  <h1 ref={scrollToRef}>
                    Explore {region.regionName || 'Other'} Beaches
                  </h1>

                  <Box sx={{ mt: 15 }}>
                    <CardList>
                      {region.beaches &&
                        region.beaches.map((b, i) => {
                          if (
                            process.env.GATSBY_STAGE === 'production' &&
                            (!b.name || b.name.includes('Test'))
                          ) {
                            return <Fragment key={i} />
                          }

                          const weatherDataIndex =
                            regions
                              .slice(0, k) // get all previous regions
                              .reduce((total, prevRegion) => {
                                return (
                                  total + (prevRegion?.beaches?.length || 0)
                                )
                              }, 0) + i

                          return (
                            <Card
                              key={b.name}
                              imageHeight={'280px'}
                              title={b.name}
                              subtitle={
                                <BeachWeatherCardSubtitle
                                  name={b.name}
                                  loading={weatherDataLoading || loading}
                                  id={k + i}
                                  weather={weatherData?.[weatherDataIndex]}
                                  aquaticRiskLevels={b?.towerHazardRatings}
                                  globalOverride={
                                    liveAgencyData?.find(
                                      (lad) => lad.id === b?.watchtowerAgencyId
                                    )?.globalOverride
                                  }
                                />
                              }
                              heroImage={b.beachImage && b.beachImage}
                              slug={`${b.slug}`}
                            />
                          )
                        })}
                    </CardList>
                  </Box>
                </Box>
              )
            })}
          </Container>
          {countyBannerData && <PartnerBanner bannerData={countyBannerData} />}
        </Flex>
      </Container>
    </Layout>
  )
}

const BeachWeatherCardSubtitle = ({
  id,
  weather,
  loading,
  aquaticRiskLevels,
  globalOverride,
  // name,
}) => {
  const { riskColor, riskStatus } = useGenerateLocationRiskInformation({
    aquaticRiskLevels,
    weatherData: weather,
    // riskData,
    // override,
    globalOverride,
  })

  return (
    <Flex
      sx={{ alignItems: 'center', justifyContent: 'flex-start', gap: '10px' }}
    >
      {loading ? (
        <Fragment>
          <Spinner height={'20px'} width={'20px'} color="primary" />
          Checking conditions...
        </Fragment>
      ) : riskStatus?.includes('Error') ? (
        <Fragment />
      ) : (
        <Flex
          sx={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Flex sx={{ alignItems: 'center', gap: '10px' }}>
            <Box
              title="Can differ tower to tower, see map for more details"
              sx={{
                bg: riskColor,
                width: '20px',
                height: '20px',
                borderRadius: '30px',
              }}
              data-tip
              data-for={`${id}`}
            />
            {riskStatus}
          </Flex>
          <Flex sx={{ alignItems: 'center' }}>
            <WaterWave title="Wave Height" weight={20} height={20} />{' '}
            {weather?.waveHeight?.formatted || 'N/A'}
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

const MapView = ({ markers }) => {
  const [popupInfo, setPopupInfo] = useState(null)
  const mapRef = useRef()

  const onClick = (marker, zoom = true) => {
    if (marker) {
      setPopupInfo(marker)
      zoom &&
        mapRef.current.fitBounds(
          [
            [marker.lon, marker.lat],
            [marker.lon, marker.lat],
          ],
          { padding: 100, duration: 1000, zoom: 15 }
        )
    }
  }
  return (
    <Map
      ref={mapRef}
      reuseMaps
      initialViewState={{
        bounds: calculateBoundingBox(markers),
        fitBoundsOptions: {
          padding: 60,
        },
      }}
      onDragStart={() => setPopupInfo(null)}
      // onZoomStart={() => setPopupInfo(null)}
      mapboxAccessToken={process.env.GATSBY_MAPBOX_TOKEN}
      mapStyle="mapbox://styles/trevorwt/clgpso1av003d01obaa9r42xa"
    >
      {markers?.map((marker, i) => {
        return (
          <Marker
            onClick={() => onClick(marker)}
            key={i}
            style={{ cursor: 'pointer' }}
            longitude={marker.lon}
            latitude={marker.lat}
            scale={10}
          >
            {popupInfo?.lat !== marker.lat ? (
              <Flex
                onMouseEnter={() => onClick(marker, false)}
                sx={{
                  p: '6px 4px',
                  bg: marker.riskColor,
                  borderRadius: '20px',
                  border: '1px solid white',
                  color: 'white',
                  fontWeight: '600',
                }}
              >
                {marker.waveHeight}
              </Flex>
            ) : (
              <Flex
                sx={{
                  p: '4px',
                  bg: marker.riskColor,
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  textAlign: 'left',
                  color: 'white',
                  borderRadius: '6px',
                  flexDirection: 'column',
                  border: '1px solid white',
                  fontWeight: '600',
                  fontSize: '12px',
                }}
              >
                <h6 sx={{ mb: '0px' }}>{marker.name} </h6>
                <div>
                  {marker.riskStatus?.includes('Error')
                    ? ''
                    : `${marker.riskStatus} ${marker.waveHeight}`}
                </div>

                <Link sx={{ color: 'white' }} to={`/${marker.slug}`}>
                  Go to beach
                </Link>
              </Flex>
            )}
          </Marker>
        )
      })}
    </Map>
  )
}

const calculateBoundingBox = (markers) => {
  let minLng = Infinity
  let minLat = Infinity
  let maxLng = -Infinity
  let maxLat = -Infinity

  markers?.forEach(({ lat, lon }) => {
    minLng = Math.min(minLng, lon)
    minLat = Math.min(minLat, lat)
    maxLng = Math.max(maxLng, lon)
    maxLat = Math.max(maxLat, lat)
  })

  return [
    [minLng, minLat], // Southwest coordinates
    [maxLng, maxLat], // Northeast coordinates
  ]
}

export default County

export const query = graphql`
  query CountyPage($slug: String!) {
    allContentfulCounty(filter: { slug: { eq: $slug } }, limit: 1) {
      nodes {
        id
        name
        slug
        noaaCountyCodes
        banner {
          gatsbyImageData(layout: FULL_WIDTH, width: 2400)
          description
          title
        }
        image {
          gatsbyImageData(layout: CONSTRAINED, width: 2000)
        }
        countyLevelBanner {
          id
          bannerName
          partners {
            id
            partnerName
            partnerUrl
            partnerDescription {
              partnerDescription
            }
            partnerLogo {
              gatsbyImageData(height: 125)
            }
          }
        }
        regions {
          id
          regionName
          beaches {
            id
            name
            slug
            watchtowerAgencyId
            towers {
              towerId
            }
            towerHazardRatings {
              name
              color
              incidentType
              min
              max
              towerMin
              towerMax
              includeOnMapKey
              icon {
                file {
                  url
                }
              }
            }
            customWeatherNoaaState
            customWeatherNoaaTideStation
            customWeatherSurflineSpotId
            timezone
            customWeatherLatLng {
              lon
              lat
            }
            beachImage {
              gatsbyImageData(layout: CONSTRAINED, width: 600)
            }
          }
        }
        descriptionText {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    allContentfulState(
      filter: { counties: { elemMatch: { slug: { eq: $slug } } } }
      limit: 1
    ) {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
  }
`
